import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { from, map, Observable, of } from 'rxjs';
import { Organizations } from '../organizations';

@Injectable()
export class OrganizationRouteGuard {
  constructor(private authService: KeycloakService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const userProfile = this.authService.getKeycloakInstance().tokenParsed;

    const organizations = Object.values(Organizations);
    return of(
      organizations.some((org) => userProfile?.organization?.includes(org)),
    );
  }
}
